import React from 'react';
import './App.css';
import KeyDetailsSection from './KeyDetails.js';
import FurtherDetailsSection from './FurtherDetails.js';
import RSVPSection from './components/RSVP.js';
import FeedbackSection from './components/Feedback.js';
import ShoutoutSection from './Shoutout.js'
import ThankYouSection from './ThankYou.js'

const TitleSection = () => (
  <section className="section title-section">
    <h1 className='title-text' data-text="Daftnes '24">Daftnes '24</h1>
  </section>
);

function App() {
  return (
    <div className="App">
      <TitleSection />
      {/* <KeyDetailsSection /> */}
      {/* <ShoutoutSection /> */}
      {/* <FurtherDetailsSection /> */}
      {/* <RSVPSection /> */}
      <ThankYouSection />
      <FeedbackSection />
    </div>
  );
}

export default App;
