import React, { useState } from 'react';
import './FeedbackSection.css';

const WEBHOOK_URL = process.env.REACT_APP_WEBHOOK_URL;

async function sendWebhook(webhookData) {
  try {
    const response = await fetch(WEBHOOK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(webhookData),
    });
    if (!response.ok) {
      throw new Error(`Webhook call failed with status: ${response.status}`);
    }
    console.log('Webhook sent successfully:', webhookData);
  } catch (error) {
    console.error('Error sending webhook:', error);
  }
}

const FeedbackSection = () => {
  const [generalFeedback, setGeneralFeedback] = useState('');
  const [toiletFeedback, setToiletFeedback] = useState('');
  const [name, setName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const formData = {
      generalFeedback,
      toiletFeedback,
      name,
    };
    await sendWebhook(formData);
  };

  return (
    <section className="section feedback-section">
      <h2>Event Feedback</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Feedback/suggestions on the event in general:</label>
          <textarea
            name="generalFeedback"
            value={generalFeedback}
            onChange={(e) => setGeneralFeedback(e.target.value)}
          />
        </div>
        <div>
          <label>Feedback on the toilet, we're very keen to make this the best event toilet around so are keen to hear about what worked, and what didn't, what you liked, and what you hated!</label>
          <textarea
            name="toiletFeedback"
            value={toiletFeedback}
            onChange={(e) => setToiletFeedback(e.target.value)}
          />
        </div>
        <div>
          <label>Name (optional, but handy in case there's anything to follow up on):</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <button type="submit">Submit Feedback</button>
        {formSubmitted && <h1>Thanks for your feedback!</h1>}
      </form>
    </section>
  );
};

export default FeedbackSection;
