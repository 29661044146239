import React from 'react';
import './App.css';

const ThankYouSection = () => (
    <section className="section thankyou-section">
    <h1>A Huge Thank You!</h1>
    <p>A massive thank you to everyone who helped make Daftnes '24 so much fun. The laughs, games, activities, dancing and all the silly moments were just awesome, and it’s all thanks to you, you beautiful bunch! Please share any pictures you took<a href="https://photos.app.goo.gl/BjN8pWacCVKRaSPU9" target="_blank" rel="noreferrer">here</a></p>

    <h2>Event Stats</h2>

    <ul>
      <li>145 meals made</li>
      <li>100 trees planted</li>
      <li>3 tonnes of CO2 offset</li>
      <li>£95 donated to charity</li>
      <li>36 adults, 4 kids, and 3 dogs joined in the fun</li>
      <li>First aid kit wasn't needed – always a relief!</li>
    </ul>

    <h2>Delicious Food</h2>
    <p>The catering was fantastic! The food was absolutely delicious, I think tequlia slammers on shift might have just become a new tradition! Top work to all those who did shifts clearing/washing up!</p>

    <h2>Shoutout to the event fairies</h2>
    <p>Big shoutout to everyone who took on extra jobs. There's a lot that needs to happen for the event to run smoothly.. Topping up water, clearing up, litter picking, restocking the bar, fetching ice, making drinks, managing the fire, picking the music, and everything else!</p>

    <h2>Build and break</h2>
    <p>This year’s setup was our biggest & best yet. Special thanks to Morgan for the many days of work put into building the new composting loo. Despite the more ambitious build, the pack down was done in record time. Huge thanks to everyone who helped out!</p>

    <h2>Recycling and Litter Pick</h2>
    <p>The recycling was all redistributed, this meant that my Nan had nothing left to deal with – a first! The litter pick was incredible, and the site was left spotless.</p>

    <h2>Compost Loo Donations</h2>
    <p>Thanks to everyone for your contributions to the compost loo! Can't wait to see all the beautiful flowers bloom on<a href="https://www.thenorthdevonflowerfarm.co.uk" target="_blank" rel="noreferrer">the farm!</a></p>

    <h2>A Message from Nan</h2>
    <p>Nan was really impressed with how respectful everyone was of the land and how quickly the site was cleaned up. She was blown away by the litter pick and recycling efforts. She's glad we all had such a great time and thinks you’re all a lovely bunch!</p>

    </section>
  );

export default ThankYouSection;